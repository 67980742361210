<template>
  <div>
    <div class="tt df">
      <div class="suxian"></div>
      账期信息
      <div
        style="margin-left:20px;"
        :class="
          orderDetail.status == 100
            ? 'tag-100'
            : orderDetail.status == 110
            ? 'tag-110'
            : orderDetail.status == 140
            ? 'tag-140'
            : ''
        "
      >
        {{
          orderDetail.status == 100
            ? "待还款"
            : orderDetail.status == 110
            ? "还款中"
            : orderDetail.status == 140
            ? "已还款"
            : ""
        }}
      </div>
    </div>
    <el-descriptions class="mt30">
      <el-descriptions-item label="账期账户编号">{{
        orderDetail.termsCode
      }}</el-descriptions-item>
      <el-descriptions-item label="申请时间">{{
        orderDetail.createTime
      }}</el-descriptions-item>
      <el-descriptions-item label="消费金额">{{
        orderDetail.amt
      }}</el-descriptions-item>
    </el-descriptions>
    <div class="tt df">
      <div class="suxian"></div>
      关联订单
    </div>
    <div class="list-box">
      <div
        class="list-li"
        v-for="(item, index) in orderDetail.orderList"
        :key="item.order.id"
      >
        <div class="head df">
          <div style="width: 100%" class="content">
            <div class="bg"></div>
            <li>
              <div class="cld realpay">
                {{ item.order.status > 110 ? "实" : "应" }}付总金额：
                <span style="color:#E33233"
                  >¥
                  {{
                    item.order.status > 100
                      ? item.order.payAmount
                      : item.order.totalAmount
                  }}</span
                >
              </div>
              <div class="su"></div>
              <div class="cld">
                订单号：
                <span>{{ item.order.orderSn }}</span>
              </div>
              <div class="su"></div>

              <div class="cld">
                下单时间：
                <span>{{ item.order.createTime }}</span>
              </div>

              <div
                class="cld"
                style="color: #26c487; flex: 1; justify-content: flex-end"
              >
                <div v-if="item.order.status == 100" class="daifukuan">
                  <span>待付款</span>
                </div>
                <div v-if="item.order.status == 110" class="daifukuan">
                  <span>支付中</span>
                </div>
                <div v-if="item.order.status == 140" class="daishouhuo">
                  待收货
                </div>
                <div v-if="item.order.status == 120" class="daifahuo">
                  待发货
                </div>
                <div v-if="item.order.status == 160" class="daishouhuo">
                  已完成
                </div>
                <div v-if="item.order.status == 170" class="yiguanbi">
                  已关闭
                </div>
              </div>
            </li>
            <li>
              <div class="cld">
                客户：
                <span>{{ item.order.customerName }}</span>
              </div>
              <div class="su"></div>
              <div class="cld">
                收货方式：
                <span>
                  {{ item.order.receiveType == 1 ? "送货上门" : "到仓自提" }}
                </span>
              </div>
            </li>
            <li class="df flex-between">
              <div class="cld">
                <div>
                  {{ item.order.receiveType == 1 ? "收货人" : "提货人" }}信息：
                </div>

                <span>
                  {{ item.order.takeName || item.order.receiveName }}
                  {{ item.order.takePhone || item.order.receivePhone }}
                  {{ item.order.receiveAddress }}
                </span>
              </div>
              <div
                v-if="item.endtime && item.order.status == 100"
                style="
                          display: flex;
                          align-items: center;
                          justify-content: flex-end;
                        "
                class="fred tar"
              >
                <img
                  style="margin-right: 5px"
                  class="icon"
                  src="@/assets/images/person/warning.png"
                  alt
                />
                剩余付款时间： {{ item.endtime }}
                <!-- 下错单了 -->
              </div>
            </li>
          </div>
        </div>
        <div>
          <div class="cardlist">
            <div v-for="(it, idx) in item.orderItems" :key="idx">
              <div v-if="idx < 2">
                <div class="bigl">
                  <Product-Card :isMyorder="true" :rows="it"> </Product-Card>
                </div>
              </div>
            </div>
            <div v-if="item.totalNum - 2 > 0" class="su"></div>
            <div v-if="item.totalNum - 2 > 0" class="listNum">
              <div class="num nwp">
                剩余{{ item.totalNum - 2 > 0 ? item.totalNum - 2 : 0 }}类商品
              </div>
            </div>

            <div v-if="item.totalNum - 2 > 0" class="su"></div>
            <div class="listNum">
              <div
                v-if="item.totalNum <= 2"
                style="margin-right:20px"
                class="su"
              ></div>
              <div
                v-if="item.order.status == 100 || item.order.status == 110"
                class="caozuo"
              >
                <div
                  @click="goPay(item)"
                  v-if="item.order.status != 110"
                  class="zhifu cup"
                >
                  立即支付
                </div>
                <div
                  @click="checkpayFn(item)"
                  v-if="item.order.status == 110"
                  class="zhifu cup"
                >
                  查看支付二维码
                </div>
                <div @click="goDetail(item)" class="xiangqing cup">
                  查看详情
                </div>
              </div>
              <div
                v-if="item.order.status != 100 && item.order.status != 110"
                style="justify-content: center"
                class="caozuo df"
              >
                <div @click="goDetail(item)" class="xiangqing gr cup">
                  查看详情
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pagenation">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="detailFrom.pageNum"
        :page-size="10"
        layout="prev, pager, next, total,jumper"
        :total="detailFrom.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/custom-base/custom-product-card";
import { getOrderDetailByPaySn } from "@/request/Orders/order";
export default {
  components: {
    ProductCard,
  },
  data() {
    return {
      orderDetail: {
        orderList: [],
      },
      orderList: [],
      detailFrom: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.detailFrom.pageNum = val;
      this.getOrderDetail();
    },
    getOrderDetail(item) {
      if (item) {
        this.orderDetail = item;
      }
      let data = {
        pageNum: this.detailFrom.pageNum,
        pageSize: this.detailFrom.pageSize,
        paySn: this.orderDetail.payOrderSn,
      };
      getOrderDetailByPaySn(data).then((res) => {
        if (res.code === 200) {
          this.orderDetail.orderList = res.data.rows;
          this.detailFrom.total = res.data.total;
          this.$forceUpdate();
        }

      });
    },
    goDetail(item) {
      this.$router.push({
        name: "OrderDetail",
        params: { id: item.order.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
.mt30 {
  margin-top: 30px;
}
.tag-100 {
  font-size: 12px;
  padding: 6px 7px;
  background: rgba(255, 103, 0, 0.2);
  border-radius: 2px;
  color: #ff6700;
}
.tag-140 {
  font-size: 12px;
  padding: 6px 7px;
  background: rgba(38, 196, 135, 0.2);
  border-radius: 2px;
  color: rgba(38, 196, 135, 1);
}
.tag-110 {
  font-size: 12px;
  padding: 6px 7px;
  background: rgba(255, 169, 54, 0.2);
  border-radius: 2px;
  color: rgba(255, 169, 54, 1);
}
.pagenation {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.list-box {
  height: 640px;
  overflow: auto;
}
.list-li {
  margin-bottom: 20px;
  width: 100%;
  background: #fff;
  border: 1px solid @hui;
  box-sizing: border-box;
  .head {
    width: 100%;
    /* height: 120px; */
    box-sizing: border-box;
    background: #f7f8fa;
    border-bottom: 1px solid @hui;
    padding: 18px 20px 11px 17px;
    .content {
      li {
        margin-bottom: 10px;
        line-height: 20px;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: #595a6d;
        .cld {
          font-size: 12px;
          display: flex;
          color: #595a6d;
        }
        .su {
          margin: 0 14px;
          border: 1px solid #e7ebe7;
          display: inline-block;
          vertical-align: middle;
          border-left: 1px solid @hui;
          content: "";
          height: 12px;
          line-height: 12px;
        }
      }
    }
  }

  .cardlist {
    align-items: center;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px 22px;
    .su {
      margin-top: 2px;
      display: inline-block;
      vertical-align: middle;
      border-right: 1px solid @hui;
      content: "";
      height: 79px;
      padding-left: 10px;
      line-height: 79px;
    }
    .listNum {
      display: flex;
      text-align: center;
      align-items: center;
      padding: 0 6px;
      .num {
        font-size: 14px;
        color: #bbbccb;
      }
    }
    .bigl:nth-child(2n) {
      margin-left: 30px;
    }

    .price {
      border-top: 1px dashed #f5f5f5;
      padding-top: 5px;
      display: flex;
      justify-content: flex-end;
      height: 20px;
      line-height: 20px;
      .ge {
        margin-right: 60px;
        font-size: 14px;
        color: #adafad;
      }
      .total {
        margin-right: 20px;
        font-size: 14px;
        font-weight: bold;
        color: @green;
      }
    }
    .lii {
      padding-bottom: 14px;
      border-bottom: 2px dashed @hui;
      display: flex;
      min-width: 350px;
      /* width: 360px; */

      .card {
        text-align: center;
        border: 1px solid @hui;
        width: 86px;
        height: 92px;
        box-sizing: border-box;
      }
      .goodsDetail {
        flex: 1;
        margin-left: 16px;
        line-height: 1.4;
        .h1 {
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: 500;
        }
        .info {
          font-size: 12px;
          color: #999999;
        }
      }
    }
    .caozuo {
      width: 80px;
      text-align: center;
      white-space: nowrap;
      .zhifu {
        font-size: 14px;
        color: #26c487;
        margin-bottom: 20px;
      }
      .xiangqing {
        font-size: 14px;
      }
    }
  }
}
</style>
