<template>
  <div>
    <router-view></router-view>
    <div style="width: 1180px">
      <header>
        <div class="box">
          <div class="list">
            <img src="@/assets/images/accountPeriod/img1.png" alt="" />
            <div class="right">
              <div class="price">{{ pageData.totalQuota || 0 }}</div>
              <div class="txt">账期总额度(元)</div>
            </div>
          </div>
          <div class="solid"></div>
          <div class="list">
            <img src="@/assets/images/accountPeriod/img2.png" alt="" />
            <div class="right">
              <div class="price">
                {{ pageData.repaidAmt || 0 }}
                <el-tooltip effect="dark" content="待还款总额度=待还款状态下明细总额+还款中状态下明细总额" placement="top">
                  <i class="el-icon-warning price-icon"></i>
                </el-tooltip>
              </div>
              <div class="txt">待还总额度(元)</div>
            </div>
          </div>
          <div class="solid"></div>
          <div class="list">
            <img src="@/assets/images/accountPeriod/img3.png" alt="" />
            <div class="right">
              <div class="price">{{ pageData.curQuota || 0 }}</div>
              <div class="txt">可用总额度(元)</div>
            </div>
          </div>
          <div class="solid"></div>
          <div class="list">
            <img src="@/assets/images/accountPeriod/img4.png" alt="" />
            <div class="right">
              <div class="price">{{ pageData.overdueAmt || 0 }}</div>
              <div class="txt">逾期额度(元)</div>
            </div>
          </div>
        </div>
      </header>
      <div style="padding-top: 32px" class="MyMessage p32">
        <div class="tt df">
          <div class="suxian"></div>
          账期使用记录
        </div>
        <div class="serchtab">
          <!-- <div class="li">
            <div class="serchName">账期账户ID</div>
            <div class="ipt">
              <el-input
                placeholder="请输入内容"
                clearable
                v-model="form.termsCode"
              >
              </el-input>
            </div>
          </div> -->
          <div class="li">
            <div class="serchName">申请时间</div>
            <div class="ipt">
              <el-date-picker
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="onChangeTimeRange"
                v-model="inputDate"
              />
            </div>
          </div>
          <div class="li">
            <div class="serchName">操作时间</div>
            <div class="ipt">
              <el-date-picker
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="onChangeOperateTimeRange"
                v-model="operateDate"
              />
            </div>
          </div>
          <div class="li">
            <div class="ss">
              <el-button @click="serchList" type="success">搜索</el-button>
              <el-button @click="reset">重置</el-button>
            </div>
          </div>
        </div>
        <div class="navlist  mb30">
          <div
            @click="swichNav('')"
            :class="form.status == '' ? 'active li' : 'li'"
          >
            全部记录
          </div>
          <el-badge :value="Repayment" class="item">
            <div
              @click="swichNav(100)"
              :class="form.status == 100 ? 'active li' : 'li'"
            >
              待还款
            </div>
          </el-badge>

          <div
            @click="swichNav(110)"
            :class="form.status == 110 ? 'active li' : 'li'"
          >
            还款中
          </div>
          <div
            @click="swichNav(120)"
            :class="form.status == 120 ? 'active li' : 'li'"
          >
            部分还款
          </div>
          <div
            @click="swichNav(140)"
            :class="form.status == 140 ? 'active li' : 'li'"
          >
            已还款
          </div>
        </div>
        <div v-if="form.status == 100" class="nav-header">
          <div class="df aic">
            <img src="../../../assets/images/Shape.png" /> 已选择<span
              class="gr"
              >{{ multipleSelection.length }}</span
            >条记录
          </div>
          <span @click="morePay" class="gr cup">批量还款</span>
        </div>
        <div class="tabel">
          <el-table
            :header-cell-style="{ background: '#F8F8F8' }"
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            :cell-style="cellStyle"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              v-if="form.status === 100"
              type="selection"
              width="55"
            >
            </el-table-column>
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="50"
            >
            </el-table-column>
            <el-table-column
              align="center"
              property="createTime"
              label="申请时间"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              property="termsCode"
              label="账期账户编号"
              width="180"
            >
            </el-table-column>

            <el-table-column
              align="center"
              property="amt"
              label="消费金额(元)"
              width="120"
            >
            </el-table-column>
            <el-table-column
              align="center"
              property="repaymentAmount"
              label="待还款金额(元)"
              width="140"
            >
              <template slot="header">
                <div>
                  <span>待还款金额(元)</span>
                  <el-tooltip effect="dark" content="待还款金额=消费金额-已还金额-售后产生金额" placement="top">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="status"
              label="状态"
              width="100"
            >
              <template slot-scope="scope">
                <div class="df jfc aic tac">
                  <div
                    style="margin-right: 5px"
                    class="del"
                    :style="
                      scope.row.status == 100 || scope.row.status == 120
                        ? 'background-color:#FF6700'
                        : scope.row.status == 110
                        ? 'background-color:#FFA936'
                        : scope.row.status == 140
                        ? 'background-color:#26C487'
                        : ''
                    "
                  ></div>
                  {{ getLabelByValue(scope.row.status, statusOptions) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="repaymentTime"
              label="截止时间"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              property="modifyTime"
              label="操作时间"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column 
              align="center"
              label="逾期时间"
              width="160"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.overdueNum > 0" style="color:#FF2222">{{scope.row.overdueNum}}天</div>
                <div v-else>--</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="name"
              label="操作"
              width="200"
              fixed="right"
            >
              <template slot-scope="scope">
                <div class="operate">
                  <div
                    @click="goOrderDetail(scope.row)"
                    style=" color: #26C487; font-size: 14px"
                    class="cup"
                  >
                    查看详情
                  </div>
                  <div
                    @click="goPay(scope.row)"
                    v-if="scope.row.status === 100 || scope.row.status === 120"
                    style="color: #26c487; font-size: 14px"
                    class="cup"
                  >
                    还款
                  </div>
                  <div
                    v-if="scope.row.status === 110"
                    style="color: #26c487; font-size: 14px"
                    class="cup"
                    @click="checkpayFn(scope.row)"
                  >
                    查看支付二维码
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="tableData.length" class="pagenation">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="form.pageNum"
            :page-size="10"
            layout="prev, pager, next, total,jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-drawer :visible.sync="drawer" size="70%" direction="rtl">
      <drawerCard ref="drawerCard"></drawerCard>
    </el-drawer>
    <!-- 微信支付弹框 -->
    <div class="paylog">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="buy_code"
        width="430px"
        @close="buy_close"
      >
        <img src="@/assets/images/quicklyOrder/payIMG.png" alt="" />
        <div
          style="
            display: flex;
            justify-content: center;
            position: absolute;
            top: 30px;
            right: 0;
            transform: translate(-70px, 165px);
          "
          class="buy_box dis_f_c_c"
        >
          <div id="qrcode" ref="qrcode" class="img1 dis_f_c_c"></div>
        </div>
        <div
          @click="buy_close"
          style="margin-top: 40px"
          class="cup flex-center"
        >
          <img src="@/assets/images/quicklyOrder/Close.png" alt="" />
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import drawerCard from "./drawer.vue";
import QRCode from "qrcodejs2"; // 引入qrcode,转二维码
import {
  termsDetailController,
  signRepayment,
  findDetailByPaySn,
} from "@/request/Orders/order";
import { getLabelByValue } from '@/utils/tool'
export default {
  components: {
    drawerCard,
  },
  data() {
    return {
      getLabelByValue,
      buy_code: false,
      drawer: false,
      inputDate: [],
      operateDate: [],
      total: 0,
      input: "",
      pageData: [],
      tableData: [],
      from: {},
      Repayment: 0,
      form: {
        termsCode: "",
        startDate: undefined,
        endDate: undefined,
        modifyStartDate: undefined,
        modifyEndDate: undefined,
        pageNum: 1,
        pageSize: 10,
        status: "",
      },
      multipleSelection: [],
      statusOptions: [
        {label: '待还款', value: '100'},
        {label: '还款中', value: '110'},
        {label: '部分还款', value: '120'},
        {label: '已还款', value: '140'},
      ]
    };
  },
  created() {
    this.getList();
    this.getRepayment();
  },
  methods: {
    buy_close() {
      this.buy_code = false;
      this.$refs.qrcode.innerHTML = "";
    },
    //查看支付二维码
    checkpayFn(item) {
      if (!item.payUrl) {
        this.$message.warning("二维码获取失败，请联系业务员");
        return;
      }
      this.buy_code = true;
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrcode, {
          width: 300, //二维码宽度
          height: 300, //二维码高度
          text: item.payUrl, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr="
          colorDark: "#000", //颜色配置
          colorLight: "#fff",
        });
      });
      //循环请求查看是否支付成功
      this.timer1 = setInterval(() => {
        this.wx_time(item.paySn);
      }, 1000);
    },
    //监听是否支付成功
    wx_time(paySn) {
      if (this.buy_code == false) {
        this.$refs.qrcode.innerHTML = "";
        //关闭弹窗后
        clearInterval(this.timer1);

        return;
      }
      findDetailByPaySn({ paySn: paySn }).then((res) => {
        if (res.code == 200) {
          if (res.data.status == 140) {
            this.paysuccess = true;
            //监听支付成功后
            //成功后关闭定时器
            clearInterval(this.timer1);
            this.$refs.qrcode.innerHTML = ""; //清空渲染的二维码
            this.buy_code = false; //关闭弹窗
            this.$message({
              type: "success",
              message: "支付成功",
            });
            this.getList();
          }
        } else {
          //成功后关闭定时器
          clearInterval(this.timer1);
          this.$refs.qrcode.innerHTML = ""; //清空渲染的二维码
          this.buy_code = false; //关闭弹窗
        }
      });
    },
    getRepayment() {
      signRepayment().then((res) => {
        if (res.code === 200) this.Repayment = res.data;
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onChangeTimeRange() {
      if (this.inputDate) {
        this.form.startDate = this.inputDate[0];
        this.form.endDate = this.inputDate[1];
      } else {
        this.form.startDate = undefined;
        this.form.endDate = undefined;
      }
      this.getList();
    },
    onChangeOperateTimeRange() {
      if (this.operateDate) {
        this.form.modifyStartDate = this.operateDate[0];
        this.form.modifyEndDate = this.operateDate[1];
      } else {
        this.form.modifyStartDate = undefined;
        this.form.modifyEndDate = undefined;
      }
      this.getList();
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "color: #333";
    },
    goOrderDetail(item) {
      this.drawer = true;
      this.$nextTick(() => {
        this.$refs.drawerCard.getOrderDetail(item);
      });
    },
    goPay(item) {
      this.$router.push({
        name: "PayAccountPeriod",
        query: { ids: [item.id] },
      });
    },
    morePay() {
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      this.$router.push({
        name: "PayAccountPeriod",
        query: { ids: ids },
      });
    },
    handleCurrentChange(val) {
      let that = this;
      this.form.pageNum = val;
      that.getList();
    },
    reset() {
      this.termsCode = undefined;
      this.inputDate = [];
      this.operateDate = [];
      this.form.startDate = undefined;
      this.form.endDate = undefined;
      this.form.modifyStartDate = undefined;
      this.form.modifyEndDate = undefined;
      this.form.pageNum = 1;
      this.getList();
    },
    swichNav(id) {
      this.form.status = id;
      this.getList();
    },
    serchList() {
      (this.form.pageNum = 1), this.getList();
    },
    getList() {
      termsDetailController(this.form).then((res) => {
        if (res.code === 200) {
          this.pageData = res.data;
          this.tableData = res.data.pageData.rows || [];
          this.total = res.data.pageData.total;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-drawer__body {
    padding: 20px;
  }
  .el-badge__content.is-fixed {
    right: 20px;
  }
  .el-range-editor.el-input__inner {
    width: 260px;
  }
}

.mt30 {
  margin-top: 30px;
}
.tag-100 {
  font-size: 12px;
  padding: 12px;
  background: rgba(255, 103, 0, 0.2);
  border-radius: 2px;
  color: #ff6700;
}
.tag-140 {
  font-size: 12px;
  padding: 12px;
  background: rgba(38, 196, 135, 0.2);
  border-radius: 2px;
  color: rgba(38, 196, 135, 1);
}
.tag-110 {
  font-size: 12px;
  padding: 12px;
  background: rgba(255, 169, 54, 0.2);
  border-radius: 2px;
  color: rgba(255, 169, 54, 1);
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 20px;
  background: #f4fffb;
  border-radius: 2px;
  border: 1px solid rgba(38, 196, 135, 0.34);
  margin-bottom: 20px;
  .gr {
    margin: 0 5px;
  }
  img {
    margin-right: 8px;
  }
}
.operate {
  display: flex;
  div {
    margin-right: 12px;
  }
}

.xuxian {
  width: 100px;
  height: 15px;
  background-image: linear-gradient(
    to right,
    @green 0%,
    @green 50%,
    transparent 50%
  );
  background-size: 23px 2px;
  background-repeat: repeat-x;
}

.tou:last-child {
  .xuxian {
    display: none;
  }
}
header {
  padding-bottom: 10px;
  background: #f3f5f7;
  .box {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 25px 50px;
  }
  .list {
    display: flex;
    img{
      width: 72px;
      height: 72px;
    }
    .right {
      margin-left: 25px;
      .price {
        margin: 5px 0 10px 0;
        font-size: 26px;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #2e323c;
      }
      .price-icon {
        font-size: 14px;
        cursor: pointer;
      }
      .txt {
        font-size: 14px;
        font-family: 'regular';
        font-weight: 400;
        color: #60666f;
      }
    }
  }
  .solid {
    border-right: 1px solid #f5f5f5;
  }
  .list:nth-child(3) {
    margin-right: 0;
    border-right: 0;
  }
}
.del {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.serchtab {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .li {
    height: 36px;
    margin-bottom: 30px;
    line-height: 36px;
    width: 25%;
    display: flex;
    justify-content: space-between;
    .serchName {
      white-space: nowrap;
      margin-right: 20px;
      font-size: 14px;
    }
    .ss {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .ipt {
      flex: 1;
    }
  }
}
.pagenation {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.tt {
  font-size: 18px;
  font-family: 'medium';
  font-weight: 500;
  color: #333333;
  line-height: 0px;
}
.navlist {
  margin-top: 20px;
  display: flex;
  .li {
    cursor: pointer;
    text-align: center;
    padding: 0 20px;
    font-size: 16px;
    padding-bottom: 15px;
    font-weight: 400;
    color: #333333;
  }
  .active {
    color: #26c487;
    border-bottom: 4px solid #26c487;
  }
}

.paylog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: none;
    border-radius: 2px;
    box-shadow: none;
    box-sizing: border-box;
  }
}
</style>
